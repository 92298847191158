<template>
  <div class="form">
    <a-spin :spinning="loading">
      <a-form>
        <CInfo
          title="台位名称"
          :labelCol="7"
          :valueCol="12"
          :value="table.name"
        />

        <CInput
          title="手机号"
          :labelCol="7"
          :valueCol="14"
          v-model:value="modelRef.mobile"
          @ok="onGetNameByMobile"
        />

        <CInput
          title="姓名"
          :labelCol="7"
          :valueCol="14"
          v-model:value="modelRef.name"
        />
        <CRadio
          title=""
          :labelCol="7"
          :valueCol="14"
          v-model:value="modelRef.sex"
          :options="sexes"
        />

        <CSelect
          title="选择餐标"
          :labelCol="7"
          :valueCol="14"
          :validate="validateInfos.comboPrice"
          v-model:value="modelRef.comboPrice"
          :options="comboUnit"
          @ok="onChangeComboUnit"
        />
        <CSelect
          title="选择人数"
          :labelCol="7"
          :valueCol="14"
          :validate="validateInfos.peopleCount"
          v-model:value="modelRef.peopleCount"
          :options="peoples"
          @ok="onChangePeoples"
        />
        <CSelect
          title="选择套餐"
          :labelCol="7"
          :valueCol="14"
          v-model:value="combo"
          :options="combos"
          @ok="onChangeCombo"
        />
        <CTextarea
          title="备注"
          :labelCol="7"
          :valueCol="14"
          v-model:value="modelRef.remark"
        />

        <div class="pre-btn" @click="onSubmit">确定</div>
      </a-form>
    </a-spin>
  </div>
</template>

<script>
import { defineComponent, toRaw, ref, reactive } from 'vue'
import CInfo from '@/components/counter/form/Info'
import CInput from '@/components/counter/form/Input'
import CSelect from '@/components/counter/form/Select'
import CRadio from '@/components/counter/form/Radio'
import CTextarea from '@/components/counter/form/Textarea'
import { OrderClass } from '@/apis/order'
import { CustomerClass } from '@/apis/user'
import { Form, message } from 'ant-design-vue'

const useForm = Form.useForm
const order = new OrderClass()
const customer = new CustomerClass()
export default defineComponent({
  components: {
    CInfo,
    CInput,
    CSelect,
    CRadio,
    CTextarea
  },

  props: {
    table: {
      type: Object,
      default: () => {}
    },

    loading: Boolean
  },

  emits: ['ok'],
  setup (props, { emit }) {
    const allCombos = ref([])
    const combos = ref([])
    const comboUnit = ref([])
    const peoples = ref([])
    const bookTimes = ref([])
    const combo = ref()

    const modelRef = reactive(order.modelRef)
    const rulesRef = reactive(order.rulesRefArrive)

    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef
    )

    const onChangeComboUnit = (e) => {
      const all = allCombos.value.filter((x) => x.unitPrice === e)
      const comboList = allCombos.value.filter((x) => x.unitPrice === e)

      combo.value = ''

      const selectCombos = []
      const selectPeoples = []
      const selectCombosV = []
      const selectPeoplesV = []

      all.map((e) => {
        if (!selectPeoples.includes(e.peopleCount)) {
          selectPeoples.push(e.peopleCount)
        }
      })
      comboList.map(e => {
        if (!selectCombos.includes(e.name)) {
          selectCombos.push(e.name)
        }
      })

      selectPeoples.map(e => {
        selectPeoplesV.push({
          label: e + '人',
          value: e
        })
      })

      selectCombos.map(e => {
        selectCombosV.push({
          label: e,
          value: e
        })
      })
      peoples.value = selectPeoplesV
      combos.value = selectCombosV
    }

    const onChangePeoples = (e) => {
      const all = allCombos.value.filter((x) => x.peopleCount === e)
      const comboList = allCombos.value.filter((x) => x.unitPrice === modelRef.comboPrice)

      combo.value = ''
      const selectCombos = []
      const selectComboUnits = []
      const selectCombosV = []
      const selectComboUnitsV = []
      all.map((e) => {
        if (!selectComboUnits.includes(e.unitPrice)) {
          selectComboUnits.push(e.unitPrice)
        }
      })

      comboList.map(e => {
        if (!selectCombos.includes(e.name)) {
          selectCombos.push(e.name)
        }
      })
      selectComboUnits.map(e => {
        selectComboUnitsV.push({
          label: e + '元',
          value: e
        })
      })

      selectCombos.map(e => {
        selectCombosV.push({
          label: e,
          value: e
        })
      })
      comboUnit.value = selectComboUnitsV
      combos.value = selectCombosV
    }

    const onChangeCombo = (e) => {
      console.log(e)
    }

    const onSubmit = () => {
      validate()
        .then(() => {
          const data = toRaw(modelRef)
          data.tableId = props.table.id
          data.comboId = getComboId()

          data.orderType = 1
          if (data.comboId === 0) {
            message.error('套餐计算错误，请刷新重试')
            return false
          }

          emit('ok', data)
        })
        .catch((e) => {
          console.log(e)
          e.errorFields.map((e) => {
            e.errors.map((x) => {
              message.error(x)
            })
          })
        })
    }

    const getComboId = () => {
      const { comboPrice, peopleCount } = toRaw(modelRef)
      let a = allCombos.value.filter(
        (x) => x.unitPrice === comboPrice && x.peopleCount === peopleCount
      )

      a = a.filter(x => x.name === combo.value)
      const b = a.length > 0 ? a[0].id : 0
      return b
    }

    let timer = 0
    const onGetNameByMobile = (e) => {
      clearInterval(timer)
      timer = setTimeout(() => {
        console.log(e)

        customer.mobile(e).then(resp => {
          modelRef.name = resp.name
          modelRef.sex = resp.sex

          message.success('欢迎光临' + resp.name + ['未知', '先生', '女士'][resp.sex])
        })
      }, 500)
    }
    return {
      validateInfos,
      modelRef,
      allCombos,
      combos,
      combo,
      peoples,
      bookTimes,
      sexes: [
        {
          label: '先生',
          value: 1
        },
        {
          label: '女士',
          value: 2
        }
      ],
      validate,
      resetFields,
      onChangeComboUnit,
      onChangeCombo,
      onChangePeoples,
      onSubmit,
      onGetNameByMobile
    }
  },

  created () {
    this.onGetAllCombos()
  },

  methods: {
    reset () {
      this.resetFields()
      this.onGetAllCombos()
    },
    async onGetAllCombos () {
      const list =
        this.$store.state.data.ALL_COMBOS.length > 0
          ? this.$store.state.data.ALL_COMBOS
          : await this.$store.dispatch('GetAllCombos')
      this.allCombos = list

      // const peoples = []
      const comboUnit = []
      const len = list.length
      // for (let i = 0; i < len; i++) {
      //   if (!peoples.includes(list[i].peopleCount)) {
      //     peoples.push(list[i].peopleCount)
      //   }
      // }

      for (let i = 0; i < len; i++) {
        if (!comboUnit.includes(list[i].unitPrice)) {
          comboUnit.push(list[i].unitPrice)
        }
      }

      // const selectPeoples = []
      const selectCombos = []

      // peoples.map((e) => {
      //   selectPeoples.push({
      //     label: e + '人',
      //     value: e
      //   })
      // })

      comboUnit.map((e) => {
        selectCombos.push({
          label: e + '元',
          value: e
        })
      })

      // this.peoples = selectPeoples
      this.comboUnit = selectCombos

      this.modelRef.comboPrice = comboUnit[0]

      this.onChangeComboUnit(comboUnit[0])
      this.modelRef.peopleCount = this.peoples[0].value
    }
  }
})
</script>

<style lang="less" scoped>
.form {
  padding: 0.4rem 0.4rem 0.7rem 0.4rem;

  .pre-btn {
    width: 2.86rem;
    height: 0.56rem;
    background: #ccc2ba;
    border-radius: 5px;
    text-align: center;
    line-height: 0.56rem;
    margin: 0 auto;
    cursor: pointer;
  }
}
</style>
