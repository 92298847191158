<template>
  <e-modal
    :visible="visible"
    :title="title"
    :width="600"
    :loading="submiting"
    @close="onClose"
    @ok="onSubmitFun"
  >
    <div class="table-list">
      <a-spin :spinning="loading">
        <ul>
          <li
            v-for="(v, k) in tableList"
            :key="k"
            :class="{ on: chooseIn(v) }"
            @click="onChoose(v)"
          >
            <div class="li-title">{{ v.table.name }}</div>
            <div class="li-price"><span>¥</span>{{ v.order.payAmount }}</div>
          </li>
        </ul>
        <div v-if="tableList.length === 0" class="empty">暂无可转台位</div>
      </a-spin>
      <div class="table-price">
        <span>订单金额：¥</span>
        {{ price }}
      </div>
    </div>
  </e-modal>
</template>

<script>
import { defineComponent, ref } from 'vue'
import {} from 'ant-design-vue'
import { TableClass } from '@/apis/table'
import { OrderClass } from '@/apis/order'

const table = new TableClass()
const order = new OrderClass()
export default defineComponent({
  emits: ['ok'],
  components: {},
  setup (props, { emit }) {
    const loading = ref(false)
    const submiting = ref(false)
    const visible = ref(false)
    const title = ref('')
    const tableOrder = ref()
    const tableList = ref([])
    const chooseList = ref([])

    const onClose = () => {
      loading.value = false
      visible.value = false
    }

    const onGetTableList = () => {
      loading.value = true

      table
        .all({
          State: 2
        })
        .then((resp) => {
          tableList.value = resp.filter(
            (x) =>
              x.order &&
              x.order.id !== tableOrder.value.order.id &&
              x.order.isSplit === false
          )
          loading.value = false
        })
    }

    const onChoose = (e) => {
      const set = new Set(chooseList.value)

      if (set.has(e.order.id)) {
        set.delete(e.order.id)
      } else {
        set.add(e.order.id)
      }

      chooseList.value = Array.from(set)
    }

    return {
      visible,
      loading,
      title,
      tableOrder,
      tableList,
      submiting,
      chooseList,

      onClose,
      onGetTableList,
      onChoose
    }
  },

  computed: {
    price () {
      let price = 0
      if (this.tableOrder) {
        price = this.tableOrder.order.payAmount

        const list = this.tableList.filter(x => this.chooseList.includes(x.order.id))

        list.map(e => {
          price += e.order.payAmount
        })
      }

      return price
    }
  },

  methods: {
    open (tableOrder) {
      this.tableOrder = tableOrder

      this.title = '连台结算：' + tableOrder.table.name

      this.onGetTableList()
      this.visible = true
    },

    onSubmitFun () {
      if (this.chooseList.length <= 0) {
        this.$message.error('请选择连台')
        return
      }

      console.log(this.chooseList, this.tableOrder.order.id)
      this.submiting = true
      order.connectOrder(this.tableOrder.order.id, this.chooseList).then(() => {
        this.$message.success('操作成功')
        this.$emit('ok')
        this.submiting = false
        this.onClose()
      })
    },

    chooseIn (e) {
      return this.chooseList.includes(e.order.id)
    }
  }
})
</script>

<style lang="less" scoped>
.table-list {
  position: relative;
  padding-bottom: 50px;
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    li {
      cursor: pointer;
      width: 130px;
      margin: 5px;
      background: #f9d6d6;
      border-radius: 3px;
      padding: 10px;
      .li-title {
        font-size: 16px;
        line-height: 30px;
      }
      .li-price{
        font-size: 12px;
        line-height: 24px;
      }
      &.on {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
      }
    }
  }

  .table-price {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 28px;
    color: #f50;
    span {
      font-size: 12px;
      color: #333;
    }
  }
}
.empty {
  font-size: 14px;
  text-align: center;
  line-height: 50px;
  color: #999;
  width: 100%;
}
</style>
