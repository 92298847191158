<template>
  <e-modal
    :visible="visible"
    title="选择优惠券"
    :width="300"
    :loading="loading"
    @close="onClose"
    @ok="onSubmitFun"
  >
    <div class="coupons-content">
      <div class="list">
        <a-radio-group v-model:value="couponsId">
          <a-radio
            :style="radioStyle"
            v-for="(v, k) in list"
            :key="k"
            :value="v.id"
          >
            <a-tag
              style="margin-right: 5px"
              :color="['green', 'orange'][v.comsumeType]"
            >
              {{ v.comsumeType === 0 ? "线下" : "线上" }}
            </a-tag>
            {{ v.title }}
          </a-radio>
        </a-radio-group>
      </div>

      <div class="number">
        <e-input-number
          :labelCol="7"
          title="使用数量"
          :min="0"
          v-model:value="count"
        />
      </div>
    </div>
  </e-modal>
</template>

<script>
import { defineComponent, ref } from 'vue'
import form from '@/mixins/form'

export default defineComponent({
  mixins: [form],
  emits: ['ok'],
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  setup (props, { emit }) {
    const loading = ref(false)
    const visible = ref(false)

    const curCoupons = ref()
    const couponsId = ref(0)
    const count = ref(1)

    const onClose = () => {
      couponsId.value = 0
      count.value = 1
      curCoupons.value = null
      loading.value = false
      visible.value = false
    }

    return {
      visible,
      loading,
      curCoupons,
      radioStyle: {
        display: 'flex',
        height: '30px',
        lineHeight: '30px'
      },
      couponsId,
      count,
      onClose
    }
  },

  methods: {
    open (orderDetail) {
      this.couponsId = orderDetail.couponsId
      this.count = orderDetail.couponsCount
      this.visible = true
    },

    onSubmitFun () {
      if (this.couponsId === 0) {
        this.$message.error('请选择优惠券')
        return
      }

      this.curCoupons = this.list.filter((x) => x.id === this.couponsId)[0]

      this.$emit('ok', {
        coupons: this.curCoupons,
        count: this.count
      })
    }
  }
})
</script>

<style lang="less" scoped>
.coupons-content {
  .list {
    width: 100%;
    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 20px;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    /* 滚动槽 */
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    /* 滚动条滑块 */
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #d3eaff;
    }
  }

  .number {
    border-top: 1px solid #ccc;
    padding-top: 10px;
  }
}
</style>
