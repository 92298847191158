<template>
  <div class="form">
    <a-spin :spinning="loading">
      <div class="info" v-if="orderDetail">
        <div class="table-title">
          {{ orderDetail.tableName }}
        </div>

        <CInfo
          title="预订姓名"
          :labelCol="7"
          :valueCol="12"
          :value="
            orderDetail.customerName +
            '(' +
            ['未知', '先生', '女士'][orderDetail.customerSex] +
            ')'
          "
        />

        <CInfo
          title="手机号码"
          :labelCol="7"
          :valueCol="12"
          :value="orderDetail.customerMobile"
          v-if="!editingUser"
        >
          <template #suffix>
            <div>
              <a-tooltip>
                <template #title>修改客户</template>
                <a @click.stop="onEditUser">
                  <EditOutlined />
                </a>
              </a-tooltip>
            </div>
          </template>
        </CInfo>

        <CInput
          v-if="editingUser"
          title="手机号"
          :labelCol="7"
          :valueCol="14"
          v-model:value="newUser.mobile"
          @ok="onGetNameByMobile"
        >
          <template #suffix>
            <div class="time-btns">
              <a-tag color="#f50" style="display: block; height: 24px">
                <a
                  @click="onEditUserSave"
                  style="color: #fff; font-weight: 400"
                >
                  保存
                </a>
              </a-tag>
              <a-tag color="#2db7f5" style="display: block; height: 24px">
                <a
                  @click="onEditUser"
                  style="color: #fff; font-weight: 400"
                >
                  取消
                </a>
              </a-tag>
            </div>
          </template>
        </CInput>

        <CInfo
        v-if="editingUser"
          title="转到客户"
          :labelCol="7"
          :valueCol="12"
          :value="newUser.name"
        />

        <CInfo
          title="就餐时间"
          :labelCol="7"
          :valueCol="12"
          :value="$filters.formatDate(orderDetail.bookTime, 'MM-DD HH:mm')"
          v-if="!editingTime"
        >
          <template #suffix>
            <div>
              <a-tooltip>
                <template #title>修改时间</template>
                <a @click.stop="onEditTime(orderDetail.bookTime)">
                  <EditOutlined />
                </a>
              </a-tooltip>
            </div>
          </template>
        </CInfo>

        <CDateTime
          v-if="editingTime"
          title="就餐时间"
          :labelCol="7"
          :valueCol="14"
          :showTime="true"
          v-model:value="bookTime"
        >
          <template #suffix>
            <div class="time-btns">
              <a-tag color="#f50" style="display: block; height: 24px">
                <a
                  @click="onEditTimeSave"
                  style="color: #fff; font-weight: 400"
                >
                  保存
                </a>
              </a-tag>
              <a-tag color="#2db7f5" style="display: block; height: 24px">
                <a
                  @click="onEditTime(orderDetail.bookTime)"
                  style="color: #fff; font-weight: 400"
                >
                  取消
                </a>
              </a-tag>
            </div>
          </template>
        </CDateTime>

        <CInfo
          title="就餐人数"
          :labelCol="7"
          :valueCol="12"
          :value="orderDetail.peopleCount"
        />
        <CInfo
          v-if="!editingRemark"
          title="就餐备注"
          :labelCol="7"
          :valueCol="12"
          :value="orderDetail.customerRemark"
        >
          <template #suffix>
            <div>
              <a-tooltip>
                <template #title>修改备注</template>
                <a @click.stop="onEditRemark(orderDetail.customerRemark)">
                  <EditOutlined />
                </a>
              </a-tooltip>
            </div>
          </template>
        </CInfo>

        <CTextarea
          v-if="editingRemark"
          title="就餐备注"
          :labelCol="7"
          :valueCol="12"
          v-model:value="customerRemark"
        >
          <template #suffix>
            <div class="time-btns">
              <a-tag color="#f50" style="display: block; height: 24px">
                <a
                  @click="onEditRemarkSave"
                  style="color: #fff; font-weight: 400"
                >
                  保存
                </a>
              </a-tag>
              <a-tag color="#2db7f5" style="display: block; height: 24px">
                <a
                  @click="onEditRemark(orderDetail.customerRemark)"
                  style="color: #fff; font-weight: 400"
                >
                  取消
                </a>
              </a-tag>
            </div>
          </template>
        </CTextarea>
      </div>

      <div class="items" v-if="orderDetail">
        <!-- <div class="item-title">
          <span>{{ orderDetail.comboName }}</span>
          <span class="canbiao">¥{{ orderDetail.comboPrice }}元/人</span>
        </div> -->

        <div :class="{ 'item-title': true, on: isEditCombo }">
          <div class="top">
            <div class="item-title-box">
              <div>
                {{ orderDetail.comboName }}
                <span class="canbiao">¥{{ orderDetail.comboPrice }}元/人</span>
              </div>
            </div>
            <div class="item-btns">
              <a-tag color="#108ee9" v-if="!isEditCombo">
                <a @click="editCombo" style="color: #fff; font-weight: 400">
                  修改套餐
                </a>
              </a-tag>
              <a-tag
                color="#f50"
                v-if="isEditCombo"
                style="display: block; height: 24px"
              >
                <a @click="onEditCombo" style="color: #fff; font-weight: 400">
                  保存
                </a>
              </a-tag>
              <a-tag
                color="#2db7f5"
                v-if="isEditCombo"
                style="display: block; height: 24px"
              >
                <a @click="editCombo" style="color: #fff; font-weight: 400">
                  取消
                </a>
              </a-tag>
            </div>
          </div>

          <div class="edit-combo">
            <CSelect
              title="修改餐标"
              :labelCol="7"
              :valueCol="14"
              v-model:value="changeComboData.comboPrice"
              :options="comboUnit"
              @ok="onChangeComboUnit"
            />
            <CSelect
              title="修改人数"
              :labelCol="7"
              :valueCol="14"
              v-model:value="changeComboData.peopleCount"
              :options="peoples"
              @ok="onChangePeoples"
            />
            <CSelect
              title="选择套餐"
              :labelCol="7"
              :valueCol="14"
              v-model:value="changeComboData.comboId"
              :options="combos"
              @ok="onChangeCombo"
            />
          </div>
        </div>

        <div class="item" v-for="(v, k) in orderItems" :key="k">
          <span>{{ v.dish.name }}</span>
          <span><CloseOutlined style="font-size: 10px" />{{ v.quantity }}</span>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { defineComponent, ref, watch, toRaw, reactive } from 'vue'
import CInfo from '@/components/counter/form/Info'
import CInput from '@/components/counter/form/Input'
import { OrderClass } from '@/apis/order'
import { CloseOutlined, EditOutlined } from '@ant-design/icons-vue'
import CSelect from '@/components/counter/form/Select'
import CDateTime from '@/components/counter/form/DateTime'
import CTextarea from '@/components/counter/form/Textarea'
import dayjs from 'dayjs'
import { message } from 'ant-design-vue'
import { CustomerClass } from '@/apis/user'

const order = new OrderClass()
const customer = new CustomerClass()
export default defineComponent({
  components: {
    CInfo,
    CloseOutlined,
    CSelect,
    EditOutlined,
    CDateTime,
    CTextarea,
    CInput
  },

  props: {
    orderId: {
      type: String,
      default: ''
    }
  },

  setup (props, { emit }) {
    const loading = ref(false)
    const orderDetail = ref()
    const orderItems = ref([])

    const editingTime = ref(false)
    const bookTime = ref()

    const isEditCombo = ref(false)
    const changeComboData = reactive({
      comboPrice: 0,
      peopleCount: 0,
      id: 0,
      comboId: 0
    })
    const allCombos = ref([])
    const combos = ref([])
    const comboUnit = ref([])
    const peoples = ref([])
    const combo = ref('')

    const onGetOrder = (id) => {
      loading.value = true
      order.detail(id).then((resp) => {
        orderDetail.value = resp
        orderItems.value = resp.orderItems.filter(
          (x) => x.dish.isMust || x.dish.type === 4
        )

        changeComboData.comboId = resp.comboId
        changeComboData.comboPrice = resp.comboPrice
        changeComboData.peopleCount = resp.peopleCount
        changeComboData.id = resp.id

        loading.value = false
      })
    }

    const editCombo = () => {
      isEditCombo.value = !isEditCombo.value

      if (isEditCombo.value) {
        const combolist = allCombos.value.filter(
          (x) =>
            x.peopleCount === changeComboData.peopleCount &&
            x.unitPrice === changeComboData.comboPrice
        )

        const combosV = []

        combolist.map((e) => {
          combosV.push({
            label: e.name,
            value: e.id
          })
        })

        combos.value = combosV
      }
    }

    const onChangeCombo = (e) => {
      changeComboData.comboId = e
      console.log(changeComboData.comboId)
    }

    const onChangeComboUnit = (e) => {
      const all = allCombos.value.filter((x) => x.unitPrice === e)
      const comboList = allCombos.value.filter(
        (x) =>
          x.unitPrice === e && x.peopleCount === changeComboData.peopleCount
      )

      changeComboData.comboId = ''

      const selectCombos = []
      const selectPeoples = []
      const selectPeoplesV = []

      all.map((e) => {
        if (!selectPeoples.includes(e.peopleCount)) {
          selectPeoples.push(e.peopleCount)
        }
      })

      comboList.map((e) => {
        if (selectCombos.filter((x) => x.label === e.name).length === 0) {
          selectCombos.push({
            label: e.name,
            value: e.id
          })
        }
      })

      selectPeoples.map((e) => {
        selectPeoplesV.push({
          label: e + '人',
          value: e
        })
      })

      peoples.value = selectPeoplesV
      combos.value = selectCombos

      const cur = changeComboData.peopleCount
      const has = selectPeoplesV.filter((x) => x.value === cur)
      if (has.length === 0) {
        changeComboData.peopleCount = selectPeoplesV[0].value
      }
    }

    const onChangePeoples = (e) => {
      const all = allCombos.value.filter((x) => x.peopleCount === e)
      const comboList = allCombos.value.filter(
        (x) => x.unitPrice === changeComboData.comboPrice && x.peopleCount === e
      )

      changeComboData.comboId = ''

      const selectCombos = []
      const selectComboUnits = []
      const selectComboUnitsV = []
      all.map((e) => {
        if (!selectComboUnits.includes(e.unitPrice)) {
          selectComboUnits.push(e.unitPrice)
        }
      })

      comboList.map((e) => {
        if (selectCombos.filter((x) => x.label === e.name).length === 0) {
          selectCombos.push({
            label: e.name,
            value: e.id
          })
        }
      })
      selectComboUnits.map((e) => {
        selectComboUnitsV.push({
          label: e + '元',
          value: e
        })
      })

      comboUnit.value = selectComboUnitsV
      combos.value = selectCombos

      const cur = changeComboData.comboPrice
      const has = selectComboUnitsV.filter((x) => x.value === cur)
      if (has.length === 0) {
        changeComboData.comboPrice = selectComboUnitsV[0].value
      }
    }

    const onEditCombo = () => {
      const data = { ...toRaw(changeComboData) }

      loading.value = true
      order
        .update(data)
        .then(() => {
          isEditCombo.value = false
          emit('change')
          onGetOrder(props.orderId)
        })
        .catch(() => {
          loading.value = false
        })
    }

    const onEditTime = (e) => {
      editingTime.value = !editingTime.value
      bookTime.value = dayjs(e)
    }

    const onEditTimeSave = () => {
      order
        .editTime({
          orderId: props.orderId,
          bookTime: bookTime.value.format()
        })
        .then(() => {
          editingTime.value = false
          emit('change')
          onGetOrder(props.orderId)
        })
    }

    const editingRemark = ref(false)
    const customerRemark = ref('')
    const onEditRemark = (e) => {
      editingRemark.value = !editingRemark.value
      customerRemark.value = e
    }

    const onEditRemarkSave = () => {
      order
        .editRemark({
          orderId: props.orderId,
          customerRemark: customerRemark.value
        })
        .then(() => {
          editingRemark.value = false
          emit('change')
          onGetOrder(props.orderId)
        })
    }

    const editingUser = ref(false)
    const newUser = reactive({
      name: '',
      mobile: ''
    })
    const onEditUser = () => {
      editingUser.value = !editingUser.value
    }

    const onEditUserSave = () => {
      order.changeCustomer2(props.orderId, newUser.mobile).then(() => {
        message.success('修改成功！')
        editingUser.value = false

        emit('change')
        onGetOrder(props.orderId)
      })
    }

    let timer = 0
    const onGetNameByMobile = (e) => {
      clearInterval(timer)
      timer = setTimeout(() => {
        customer.mobile(e).then((resp) => {
          if (resp) {
            newUser.name = resp.name
          } else {
            newUser.name = '没有该用户'
          }
        })
      }, 500)
    }

    onGetOrder(props.orderId)

    watch(props, (e) => {
      console.log(e.orderId)
      onGetOrder(e.orderId)
    })

    return {
      sexes: [
        {
          label: '先生',
          value: 1
        },
        {
          label: '女士',
          value: 2
        }
      ],
      orderDetail,
      editingUser,
      loading,
      orderItems,
      comboUnit,
      combo,
      allCombos,
      combos,
      peoples,
      changeComboData,
      isEditCombo,
      editingTime,
      bookTime,
      editingRemark,
      customerRemark,

      onGetOrder,
      editCombo,
      onChangeCombo,
      onChangeComboUnit,
      onChangePeoples,
      onEditCombo,
      onEditTime,
      onEditTimeSave,

      onEditRemark,
      onEditRemarkSave,
      onEditUser,
      onEditUserSave,
      onGetNameByMobile,
      newUser
    }
  },

  created () {
    this.onGetAllCombos()
  },

  unmounted () {
    this.isEditCombo = false
  },

  methods: {
    async onGetAllCombos () {
      const list =
        this.$store.state.data.ALL_COMBOS.length > 0
          ? this.$store.state.data.ALL_COMBOS
          : await this.$store.dispatch('GetAllCombos')
      this.allCombos = list

      const peoples = []
      const comboUnit = []
      const len = list.length
      for (let i = 0; i < len; i++) {
        if (!peoples.includes(list[i].peopleCount)) {
          peoples.push(list[i].peopleCount)
        }
      }

      for (let i = 0; i < len; i++) {
        if (!comboUnit.includes(list[i].unitPrice)) {
          comboUnit.push(list[i].unitPrice)
        }
      }

      const selectPeoples = []
      const selectCombos = []

      peoples.map((e) => {
        selectPeoples.push({
          label: e + '人',
          value: e
        })
      })

      comboUnit.map((e) => {
        selectCombos.push({
          label: e + '元',
          value: e
        })
      })

      this.peoples = selectPeoples
      this.comboUnit = selectCombos
    }
  }
})
</script>

<style lang="less" scoped>
.form {
  .info {
    background: #fef7d8;
    padding: 0.2rem 0;
    .table-title {
      font-size: 0.18rem;
      padding-left: 0.5rem;
      line-height: 0.5rem;
    }

    .time-btns {
      display: flex;
      padding-top: 5px;
    }
  }

  .items {
    // .item-title {
    //   display: flex;
    //   justify-content: space-between;
    //   height: 0.47rem;
    //   align-items: center;
    //   padding: 0 0.2rem;
    //   border-bottom: 3px solid #ebebeb;
    //   font-size: 0.16rem;
    //   color: #333;
    //   font-weight: 600;
    //   background: #f8f8f8;
    //   .canbiao {
    //     font-size: 0.18rem;
    //     color: #ff4f4f;
    //   }
    // }

    .item-title {
      height: 0.5rem;
      line-height: 0.5rem;
      padding: 0 0.2rem;
      border-bottom: 3px solid #ebebeb;
      font-size: 0.16rem;
      color: #333;
      font-weight: 600;
      position: relative;
      overflow: hidden;
      transition: all 0.2s ease;
      background: #f8f8f8;
      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .item-title-box {
        width: 3rem;
        height: 0.5rem;
        display: flex;
        align-items: center;
        line-height: 0.2rem;
        font-size: 0.14rem;
      }
      .item-btns {
        display: flex;
        align-items: center;
      }
      .canbiao {
        color: #ff4f4f;
      }
      .edit-combo {
        position: absolute;
        bottom: -10rem;
        left: 0;
        width: 100%;
        font-weight: 400;
        transition: all 0.2s ease;
      }
      &.on {
        height: 250px;
        .edit-combo {
          bottom: 0;
        }
      }
    }

    .item {
      padding: 0 0.2rem;
      font-size: 0.14rem;
      line-height: 0.5rem;
      border-bottom: 1px solid #ebebeb;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #666;
    }
  }
}
</style>
