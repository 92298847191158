<template>
  <e-modal
    :visible="visible"
    title="添加临时菜"
    :width="400"
    :loading="loading"
    @close="onClose"
    @ok="onSubmitFun"
  >
    <div class="dish-content">
      <div class="form">
        <a-spin tip="Loading..." :spinning="loading">
          <a-form>
            <e-info
              title="台位名称"
              :labelCol="6"
              :valueCol="16"
              v-if="tableOrder && tableOrder.table"
              :value="tableOrder.table.name"
            />
            <e-info
              title="就餐时间"
              :labelCol="6"
              :valueCol="16"
              v-if="tableOrder && tableOrder.table"
              :value="
                $filters.formatDate(tableOrder.order.bookTime, 'MM-DD HH:mm')
              "
            />

            <e-input
              :labelCol="6"
              :valueCol="16"
              title="菜品名称"
              v-model:value="modelRef.dishName"
            />

            <e-input-number
              :labelCol="6"
              :valueCol="16"
              title="菜品价格"
              v-model:value="modelRef.price"
            />

            <e-select
              :labelCol="6"
              :valueCol="16"
              title="选择菜品"
              v-model:value="modelRef.dishId"
              :options="dishList"
            />

            <e-input-number
              :labelCol="6"
              :valueCol="16"
              title="数量"
              v-model:value="modelRef.count"
            />

            <e-radio
              :labelCol="6"
              :valueCol="16"
              title="状态"
              v-model:value="modelRef.state"
              :options="stateOptions"
            />
            <s-select
              v-if="modelRef.state === 1"
              :labelCol="6"
              :valueCol="16"
              title="赠菜用户"
              v-model:value="modelRef.operatorId"
              :options="operatorOptions"
            />

            <e-radio
                :labelCol="6"
                :valueCol="16"
                title="上菜"
                v-model:value="modelRef.timing"
                :options="[{label: '即起', value: 0},{label: '等叫', value: 1}]"
              />

            <e-textarea :labelCol="6"
                :valueCol="16"
                title="备注"
                v-model:value="modelRef.remark" />
          </a-form>
        </a-spin>
      </div>
    </div>
  </e-modal>
</template>

<script>
import { defineComponent, ref, toRaw, reactive } from 'vue'
import form from '@/mixins/form'
import { Form } from 'ant-design-vue'
import { OrderItemClass } from '@/apis/order'

const useForm = Form.useForm
const api = new OrderItemClass()
export default defineComponent({
  mixins: [form],
  emits: ['ok'],
  components: {},
  setup (props, { emit }) {
    const loading = ref(false)
    const visible = ref(false)
    const tableOrder = ref()
    const dishList = ref([])

    const operatorOptions = ref([])

    const modelRef = reactive(api.modelRef)
    const rulesRef = reactive(api.rulesRef)

    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef
    )

    const onClose = () => {
      resetFields()
      loading.value = false
      visible.value = false
    }

    return {
      visible,
      loading,
      validateInfos,
      modelRef,
      tableOrder,
      operatorOptions,
      dishList,
      stateOptions: [
        {
          label: '正常',
          value: 0
        },
        {
          label: '赠送',
          value: 1
        }
      ],

      validate,
      onClose,
      resetFields
    }
  },

  methods: {
    async getManagersAndCurrUser () {
      const list =
        this.$store.state.data.ALL_MANAGES.length > 0
          ? this.$store.state.data.ALL_MANAGES
          : await this.$store.dispatch('GetManagersAndCurrUser')

      const vlist = []
      list.map((e) => {
        vlist.push({
          label: e.name,
          value: e.id
        })
      })

      this.modelRef.operatorId = this.$store.state.user.userInfo.id
      this.operatorOptions = vlist
    },
    async getTempDish () {
      const list =
        this.$store.state.data.ALL_TEMPDISH.length > 0
          ? this.$store.state.data.ALL_TEMPDISH
          : await this.$store.dispatch('GetAllTempDishs')

      const vlist = []
      list.map((e) => {
        vlist.push({
          label: e.name,
          value: e.id
        })
      })

      this.dishList = vlist
    },
    open (formData, orderId) {
      this.tableOrder = formData
      this.modelRef.orderId = orderId
      this.getTempDish()
      this.getManagersAndCurrUser()
      this.visible = true
    },

    onSubmitFun () {
      const data = toRaw(this.modelRef)
      if (!data.tableId) {
        delete data.tableId
      }

      this.validate().then(() => {
        this.loading = true

        api.add(data).then(() => {
          this.$message.success(data.state === 0 ? '加菜成功' : '赠菜成功')
          this.onClose()
          this.$emit('ok')
        }).catch(() => {
          this.loading = false
        })
      })
    }
  }
})
</script>

<style lang="less" scoped></style>
