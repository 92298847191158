import { BaseClass } from './base'

export class ConfigClass extends BaseClass {
  gets () {
    return this.get(this.config)
  }

  detail (key) {
    return this.get(this.config + `/${key}`)
  }

  update (data) {
    return this.put(this.config, data)
  }
}
